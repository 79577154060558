import dineroLogo from "../../../assets/images/logos/dinero.png"
import styles from "./DineroLogo.module.scss"
const DineroLogo = () => {
    return (
        <div className={styles.logo}>
            <img src={dineroLogo} alt="Dinero"/>
            <h1>DINERO</h1>
        </div>
    );
};

export default DineroLogo;