import MainContainer from '../../containers/MainContainer/MainContainer';
import handshake1 from '../../assets/images/PartnerShip/handshake1.png';
import handshake2 from '../../assets/images/PartnerShip/handshake2.png';
import Button from '../../ui/Button/Button';
import styles from './PartnerShip.module.scss';
import { v4 } from 'uuid';
import { partnerShipData } from '../../core/data/partnerShipData/partnerShipData';
import chevron from '../../assets/images/cardRegistration/chevron.svg';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import accordionStyles from '../CardRegistration/CardRegistration.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { useStyles } from '../../core/hooks/useStyles';
import FeedBack from '../../ui/FeedBack/FeedBack';
const Banner = () => {
	return (
		<section className={styles.banner}>
			<h1>Партнерская программа DINERO</h1>
			<div className={styles.bannerContainer}>
				<div>
					<p>
						Рекомендуйте наш сервис DINERO и получайте вознаграждение за каждого
						привлеченного клиента
						<br />
						<br />
						Cредний доход партнёра - 30 000 ₽ в месяц
						<br />
						<br />
						Используйте вознаграждение по вашему усмотрению
						<br />
						<br />
						Поможем Вам больше зарабатывать вместе с нами
					</p>
					<Button>Оставить заявку</Button>
				</div>
				<img src={handshake1} alt='Партнерская программа DINERO' />
			</div>
		</section>
	);
};
const Payment = () => {
	return (
		<section className={styles.payment}>
			<h1>Как зарабатывает Партнер</h1>
			<div className={styles.bannerCardsContainer}>
				{partnerShipData.map((item, index) => (
					<div key={v4()}>
						<div className={styles.index}>
							<p>{index + 1}</p>
						</div>
						<p className={styles.text}>{item}</p>
					</div>
				))}
			</div>
		</section>
	);
};
const Levels = () => {
	return (
		<section className={styles.levels}>
			<h1>Уровни программы Партнеров</h1>
			<div className={styles.levelsContainer}>
				<div className={styles.LevelItem}>
					<div>
						<h3>Уровень А:</h3>
						<p>доходы зависят от вашей активности</p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
				</div>
				<div className={styles.LevelItem}>
					<div>
						<h3>Уровень B:</h3>
						<p>доходы зависят от вашей активности</p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
				</div>
				<div className={styles.LevelItem}>
					<div>
						<h3>Уровень C:</h3>
						<p>доходы зависят от вашей активности</p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
					<div>
						<p>Фиксированный процент вознаграждения за каждого клиента </p>
					</div>
				</div>
			</div>
		</section>
	);
};

const Chevron = () => <img src={chevron} alt='' />;
const Faq = () => {
	const classes = useStyles();

	return (
		<section className={accordionStyles.faq}>
			<h1>Ответы на частые вопросы</h1>
			<div className={accordionStyles.accordionContainer}>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какое образование требуется?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какой график?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Есть ли ограничения по возрасту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как проходит обучение?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что понадобится для работы или оказания услуг?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что если я из другой страны?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Можно ли совмещать с учебой или занятостью в другой компании?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какие есть бонусы и скидки?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что делать, если вакансия есть на hh.ru, но нет в списке на этой
							странице?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Где посмотреть вакансии в IT и в бизнес направлениях?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</section>
	);
};

const tabs = tab => {
	switch (tab) {
		case 1:
			return 'Оплата сервисов, кинотеатров, стриминговых платформ, музыкальных приложений, игр, оплата софта, оплата антивируса, оплата цифровых товаров';
		case 2:
			return 'Управляйте средствами между членами команды и контролируйте расходы компании.Оплата софта, оплата антивируса, оплата цифровых товаров';
		case 3:
			return 'Оплата небольших счетов зарубежным сервисам, развлечение, размещение и т.д. Возможность оформить в аренду банковскую карту на период туристической поездки. Оформление зарубежнойбанковской карты для использования во время отпуска. Возможность оплатить туроператору со счета российской компании';
		case 4:
			return 'Оплата небольших счетов зарубежным сервисам, развлечение, размещение и т.д. Возможность оформить в аренду банковскую карту на период туристической поездки.';
		case 5:
			return 'Клиринговые операции по новым и текущим контрактам';
		default:
			return 'Оплата сервисов, кинотеатров, стриминговых платформ, музыкальных приложений, игр, оплата софта, оплата антивируса, оплата цифровых товаров';
	}
};
const PotentialClients = () => {
	const [type, setType] = useState(1);
	return (
		<section className={styles.potential}>
			<h1>Потенциальные клиенты сервиса DINERO</h1>
			<div className={styles.potentialContainer}>
				<div className={styles.switches}>
					<div
						onClick={() => setType(1)}
						className={classNames({
							[styles.active]: type === 1
						})}
					>
						<p>Физическим лицам</p>
					</div>
					<div
						onClick={() => setType(2)}
						className={classNames({
							[styles.active]: type === 2
						})}
					>
						<p>Фрилансерам, дизайнерам, геймерам, командам разработиков</p>
					</div>
					<div
						onClick={() => setType(3)}
						className={classNames({
							[styles.active]: type === 3
						})}
					>
						<p>Туристам</p>
					</div>
					<div
						onClick={() => setType(4)}
						className={classNames({
							[styles.active]: type === 4
						})}
					>
						<p>Туристическим операторам</p>
					</div>
					<div
						onClick={() => setType(5)}
						className={classNames({
							[styles.active]: type === 5
						})}
					>
						<p>Компаниям ведущим ВЭД</p>
					</div>
				</div>
				<div>
					<p>{tabs(type)}</p>
				</div>
			</div>
		</section>
	);
};
const Form = () => {
	return (
		<section className={styles.info}>
			<form>
				<h1>Стать участником программы партнерства</h1>
				<label>
					<input type='text' placeholder={'Фамилия, имя и отчество*'} />
				</label>
				<label>
					<input type='text' placeholder={'Город*'} />
				</label>
				<label>
					<input type='text' placeholder={'Дата рождения*'} />
				</label>
				<div>
					<label>
						<input type='text' placeholder={'Телефон*'} />
					</label>
					<label>
						<input type='text' placeholder={'E-mail*'} />
					</label>
				</div>
				<div className={styles.border}></div>
				<Button>Отправить</Button>
			</form>
			<img src={handshake2} alt='Стать участником программы партнерства' />
		</section>
	);
};

const PartnerShip = () => {
	return (
		<MainContainer>
			<Banner />
			<Payment />
			<Levels />
			<PotentialClients />
			<Form />
			<Faq />
			<FeedBack />
		</MainContainer>
	);
};

export default PartnerShip;
