import card1 from '../../../assets/images/cardRegistration/cards/card1.png';

export const bannerData = [
	'Ознакомьтесь с условиями кредитных организаций.',
	'С помощью Вашего персонального менеджера оформите все необходимые документы',
	'В личном кабинете следите за статусом Вашей карты. При необходимости наш сотрудник уточнит у Вас необходимую информацию',
	'Получение одобрения банка',
	'Ваш персональный менеджер согласует с Вами удобную форму передачи Вам карты',
	'После получения карты наши специалисты готовы помогать Вам во взаимодействии с банком'
];
export const paymentData = [
	'Выберите необходимый сервис или товар',
	'Зарегистрируйтесь в личном кабинете. Оформите заявку на оплату',
	'Получите уведомление об оплате'
];
export const cards = [
	{
		type: 'Для путешествий',
		title: 'Дебетовая карта Freedom Finance',
		description:
			'Карта для путешествий с любыми авиакомпаниями. Копите мили и оплачивайте до 100% стоимости авиабилета',
		limit: 700000,
		cashback: 10,
		serviceCost: 1890,
		serviceType: 'Yearly',
		link: '/',
		image: card1
	},
	{
		type: 'Для путешествий',
		title: 'Дебетовая карта Freedom Finance',
		description:
			'Карта для путешествий с любыми авиакомпаниями. Копите мили и оплачивайте до 100% стоимости авиабилета',
		limit: 700000,
		cashback: 10,
		serviceCost: 1890,
		serviceType: 'Yearly',
		link: '/',
		image: card1
	},
	{
		type: 'Для путешествий',
		title: 'Дебетовая карта Freedom Finance',
		description:
			'Карта для путешествий с любыми авиакомпаниями. Копите мили и оплачивайте до 100% стоимости авиабилета',
		limit: 700000,
		cashback: 10,
		serviceCost: 1890,
		serviceType: 'Yearly',
		link: '/',
		image: card1
	}
];
