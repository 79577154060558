import MainContainer from '../../containers/MainContainer/MainContainer';
import chevron from '../../assets/images/cardRegistration/chevron.svg';
import { useStyles } from '../../core/hooks/useStyles';
import accordionStyles from '../CardRegistration/CardRegistration.module.scss';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import banner from '../../assets/images/Contacts/banner.png';
import card1 from '../../assets/images/Contacts/card1.png';
import card2 from '../../assets/images/Contacts/card2.png';
import buisness from '../../assets/images/Contacts/buisness.png';
import map from '../../assets/images/Contacts/map.png';
import partner1 from '../../assets/images/Contacts/partner1.png';
import partner2 from '../../assets/images/Contacts/partner2.png';
import partner3 from '../../assets/images/Contacts/partner3.png';
import logoSet from '../../assets/images/Contacts/logoSet.png';
import styles from './Contacts.module.scss';
const Banner = () => {
	return (
		<section className={styles.banner}>
			<div>
				<h1>Контакты</h1>
				<p>Свяжитесь с нами Мы с радостью ответим на Ваши вопросы</p>
			</div>
			<img src={banner} alt='Контакты' />
		</section>
	);
};
const Individual = () => {
	return (
		<section className={styles.individual}>
			<h1>Для частных лиц</h1>
			<div>
				<div>
					<img src={card1} alt='Банковские карты' />
					<h3>Банковские карты</h3>
					<div>
						<p>Вопросы по картам</p>
						<a href='tel:8800555-10-10'>8 800 555-10-10</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Вопросы по доставке</p>
						<a href='tel:8800555-09-10'>8 800 555-09-10</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Для звонков из других стран</p>
						<a href='tel:+7495648-11-11'>+7 495 648-11-11</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Электронная почта</p>
						<a href='mailto:credit@tinkoff.ru'>credit@tinkoff.ru</a>
					</div>
				</div>
				<div>
					<img src={card2} alt='Аренда банковской карты' />
					<h3>Аренда банковской карты</h3>
					<div>
						<p>Вопросы по картам</p>
						<a href='tel:8800555-10-10'>8 800 555-10-10</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Вопросы по доставке</p>
						<a href='tel:8800555-09-10'>8 800 555-09-10</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Для звонков из других стран</p>
						<a href='tel:+7495648-11-11'>+7 495 648-11-11</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Электронная почта</p>
						<a href='mailto:credit@tinkoff.ru'>credit@tinkoff.ru</a>
					</div>
				</div>
			</div>
		</section>
	);
};
const Buisness = () => {
	return (
		<section className={styles.buisness}>
			<h1>Для бизнеса</h1>
			<div>
				<div className={styles.imageContainer}>
					<img src={buisness} alt='Для бизнеса' />
				</div>

				<div>
					<div>
						<p>Для действующих бизнес-клиентов</p>
						<a href='tel:8 800 700-66-66'>8 800 700-66-66</a>
						<p>
							<span>Бесплатно</span>
						</p>
					</div>
					<div>
						<p>Для звонков из других стран</p>
						<a href='tel:+7495648-11-10'>+7 495 648-11-10</a>
					</div>
					<div>
						<p>Электронная почта</p>
						<a href='mailto:business.welcome@tinkoff.ru'>
							business.welcome@tinkoff.ru
						</a>
					</div>
					<div>
						<p>Обсудить сотрудничество в чате</p>
						<a href={'https://t.me/BusinessTinkoffBot/'}>Написать в Telegram</a>
					</div>
				</div>
			</div>
		</section>
	);
};
const ForPartners = () => {
	return (
		<section className={styles.partners}>
			<h1>Для партнеров</h1>
			<div>
				<div>
					<img src={partner1} alt='Кредитные учреждения' />
					<h3>Кредитные учреждения</h3>
					<div>
						<div>
							<p>Выбрать направление партнерства</p>
							<a href='mailto:credit@tinkoff.ru'>credit@tinkoff.ru</a>
						</div>
						<div>
							<p>Разместить рекламу</p>
							<a href='mailto:advert@tinkoff.ru'>advert@tinkoff.ru</a>
						</div>
						<div>
							<p>Вопросы по сотрудничеству</p>
							<a href='tel:8800555-10-10'>8 800 555-10-10</a>
						</div>
					</div>
				</div>
				<div>
					<img src={partner2} alt='Для сервисов и e-commerce' />
					<h3>Для сервисов и e-commerce</h3>
					<div>
						<div>
							<p>Выбрать направление партнерства</p>
							<a href='mailto:credit@tinkoff.ru'>credit@tinkoff.ru</a>
						</div>
						<div>
							<p>Разместить рекламу</p>
							<a href='mailto:advert@tinkoff.ru'>advert@tinkoff.ru</a>
						</div>
						<div>
							<p>Вопросы по сотрудничеству</p>
							<a href='tel:8800555-10-10'>8 800 555-10-10</a>
						</div>
					</div>
				</div>
				<div>
					<img src={partner3} alt='Для компаний' />
					<h3>Для компаний</h3>
					<div>
						<div>
							<p>Выбрать направление партнерства</p>
							<a href='mailto:credit@tinkoff.ru'>credit@tinkoff.ru</a>
						</div>
						<div>
							<p>Разместить рекламу</p>
							<a href='mailto:advert@tinkoff.ru'>advert@tinkoff.ru</a>
						</div>
						<div>
							<p>Вопросы по сотрудничеству</p>
							<a href='tel:8800555-10-10'>8 800 555-10-10</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const Chevron = () => <img src={chevron} alt='' />;
const Faq = () => {
	const classes = useStyles();

	return (
		<section className={accordionStyles.faq}>
			<h1>Ответы на частые вопросы</h1>
			<div className={accordionStyles.accordionContainer}>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как активировать кредитную карту
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как узнать баланс карты
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как узнать минимальный платеж
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как повысить кредитный лимит
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как узнать, что с кредитной заявкой
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как заблокировать карту
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как перевыпустить карту
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как изменить ПИН-код
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</section>
	);
};

const Map = () => {
	return (
		<section className={styles.map}>
			<h1>Офис</h1>
			<div>
				<div>
					<p>
						Адрес: ул. Хуторская 2-я, д. 38А, стр. 26,
						<br />
						Москва, 127287 Россия
					</p>
					<div>
						<p>Телефон</p>
						<a href='tel:+7495645-59-09'>+7 495 645-59-09</a>
					</div>
					<div>
						<p>Пресс-служба</p>

						<a href='mailto:media@tinkoff.ru'>media@tinkoff.ru</a>
					</div>
					<img src={logoSet} alt='' />
				</div>
				<img src={map} alt='Офис' />
			</div>
		</section>
	);
};
const Contacts = () => {
	return (
		<MainContainer>
			<Banner />
			<Individual />
			<Buisness />
			<ForPartners />
			<Faq />
			<Map />
		</MainContainer>
	);
};

export default Contacts;
