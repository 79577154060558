import styles from '../../screens/PartnerShip/PartnerShip.module.scss';
import star from '../../assets/images/PartnerShip/star.png';

const FeedBack = () => {
	return (
		<section className={styles.feedback}>
			<h1>Отзывы клиентов</h1>
			<div className={styles.feedbackContainer}>
				<div className={styles.feedbackItem}>
					<h3>Арслан Б.</h3>
					<p>17 июня 2022 г.</p>
					<div>
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
					</div>
					<p>
						Часто заказываю у них чай, кофе и хоз товары. Все привозят в
						качественно упакованной коробке или пакетах. Бывают скидки и хочу
						отдельно, что есть быстрая доставка Ozon Express. Поддержка готова
						всегда помочь, а в личном кабинете есть абсолютно все. Никаких
						нареканий и претензий за целый год работы с ними.
					</p>
				</div>
				<div className={styles.feedbackItem}>
					<h3>Анна Ч.</h3>
					<p>17 июня 2022 г.</p>
					<div>
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
					</div>
					<p>
						Очень выручила доставка, пока лежали с ребёнком в больнице, а это
						практически год- несколько раз в неделю заказывала, по мере
						окончания детского питания, расходников, тк в палате мало места для
						хранения)). Всегда все четко выполняется по заказу, ни разу не
						подвели. Без проблем удалось вернуть по ошибке оформленный заказ.
						Продукция всегда
					</p>
				</div>
				<div className={styles.feedbackItem}>
					<h3>Мария Л.</h3>
					<p>12 мая 2022 г.</p>
					<div>
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
						<img src={star} alt='' />
					</div>
					<p>
						Быстрая и удобная доставка, всегда есть, что выбрать - ассортимент
						большой. Заказываем во ВкусВилле постоянно второй год. Продукты
						качественные, если срок годности близок к концу - сборщики звонят и
						спрашивают, будем брать или нет. Хорошая клиентская служба,
						вежливые, проблемы решают быстро - когда однажды привезли неполный
						заказ - прислали
					</p>
				</div>
			</div>
		</section>
	);
};
export default FeedBack;
