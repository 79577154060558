import apple from '../../../assets/images/AllProducts/apple.png';
import netflix from '../../../assets/images/AllProducts/netflix.png';
import youtube from '../../../assets/images/AllProducts/youtube.png';
import faceapp from '../../../assets/images/AllProducts/faceapp.png';
import playStation from '../../../assets/images/AllProducts/playStation.png';
import patreon from '../../../assets/images/AllProducts/patreon.png';
import shopify from '../../../assets/images/AllProducts/shopify.png';
import microsoft from '../../../assets/images/AllProducts/microsoft.png';
export const allProductsData = [
	{ title: 'Оплатить сервисы Apple', img: apple },
	{ title: 'Оплатить сервис Netflix', img: netflix },
	{ title: 'Оплатить сервис YouTube Premium', img: youtube },
	{ title: 'Оплатить сервис FaceApp', img: faceapp },
	{ title: 'Оплатить сервис Patreon', img: patreon },
	{ title: 'Оплатить сервис PlayStation', img: playStation },
	{ title: 'Оплатить сервисы Shopify', img: shopify },
	{ title: 'Оплатить сервис Microsoft', img: microsoft },
	{ title: 'Оплатить сервис Patreon', img: patreon },
	{ title: 'Оплатить сервис PlayStation', img: playStation },
	{ title: 'Оплатить сервисы Shopify', img: shopify },
	{ title: 'Оплатить сервис Microsoft', img: microsoft }
];
