import React, { useState } from 'react';

import clearing1 from '../../assets/images/Payment/Clearing1.png';
import clearing2 from '../../assets/images/Payment/Clearing2.png';
import Button from '../../ui/Button/Button';
import banner from '../../assets/images/Payment/Banner.png';
import styles from './Payment.module.scss';
import autoPayment from '../../assets/images/Payment/AutoPayment.png';
import { makeStyles } from '@mui/styles';
import apple from '../../assets/images/companyLogos/apple.png';
import paymentApple from '../../assets/images/companyLogos/paymentApple.png';
import youtube from '../../assets/images/companyLogos/youtube.svg';
import netflix from '../../assets/images/companyLogos/netflix.svg';
import faceapp from '../../assets/images/companyLogos/faceapp.svg';
import patreon from '../../assets/images/companyLogos/patreon.svg';
import playStation from '../../assets/images/companyLogos/playStation.svg';
import shopify from '../../assets/images/companyLogos/shopify.svg';
import hetzer from '../../assets/images/companyLogos/hetzer.svg';
import zoom from '../../assets/images/companyLogos/zoom.svg';
import amazon from '../../assets/images/companyLogos/amazon.svg';
import googleDrive from '../../assets/images/companyLogos/googleDrive.svg';
import airbnb from '../../assets/images/companyLogos/airbnb.svg';
import microsoft from '../../assets/images/companyLogos/microsoft.png';
import { paymentData } from '../../core/data/cardRegistrationData/cardRegistrationData';
import { v4 } from 'uuid';
import MainContainer from '../../containers/MainContainer/MainContainer';
import { useOutsideClick } from '../../core/hooks/useClickOutside';
import Missing from '../../ui/Missing/Missing';
import ServiceForm from '../../ui/ServiceForm/ServiceForm';
import Search from '../../ui/Search/Search';
import classNames from 'classnames';

const PaymentService = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	return (
		<section className={styles.paymentService}>
			<div className={styles.paymentServiceHeading}>
				<h1>Оплатим сервисы в любой стране мира</h1>
				<p>Выберите необходимые Вам сервисы</p>
			</div>
			<section className={styles.services}>
				<div className={styles.findService}>
					<h3>Подбери нужный сервис</h3>
					<div>
						<div className={styles.serviceCard}>
							<h6>Россиянам</h6>
							<p>Оплата зарубежных сервисов, магазинов, подписок</p>
						</div>
						<div className={styles.serviceCard}>
							<h6>Иностранцам</h6>
							<p>Оплата на территории РФ</p>
						</div>
					</div>

					<Search />
				</div>
				<div className={styles.payService} id={'payment'}>
					<div className={styles.payServiceContainer}>
						<div
							className={classNames(styles.payServiceCard, {
								[styles.activeCard]: activeIndex === 0
							})}
						>
							<h1>Оплатить сервисы Apple</h1>
							<img src={paymentApple} alt='apple' />
							<Button onClick={() => setActiveIndex(0)}>Выбрать</Button>
						</div>
						<div
							className={classNames(styles.payServiceCard, {
								[styles.activeCard]: activeIndex === 1
							})}
						>
							<h1>Оплатить сервис Netflix</h1>
							<img src={netflix} alt='netflix' />
							<Button onClick={() => setActiveIndex(1)}>Выбрать</Button>
						</div>
						<div
							className={classNames(styles.payServiceCard, {
								[styles.activeCard]: activeIndex === 2
							})}
						>
							<h1>Оплатить сервис YouTube Premium</h1>
							<img src={youtube} alt='youtube' />
							<Button onClick={() => setActiveIndex(2)}>Выбрать</Button>
						</div>
					</div>
					<div className={styles.nav}>
						<div
							className={classNames({ [styles.activeNav]: activeIndex === 0 })}
						></div>
						<div
							className={classNames({ [styles.activeNav]: activeIndex === 1 })}
						></div>
						<div
							className={classNames({ [styles.activeNav]: activeIndex === 2 })}
						></div>
					</div>
					<Button>Все</Button>
				</div>
				<ServiceForm />
			</section>
		</section>
	);
};

const useModalStyles = makeStyles(theme => ({
	button: {
		margin: 2
	},
	input: {
		display: 'none'
	}
}));
const Banner = () => {
	return (
		<section className={styles.banner}>
			<div className={styles.bannerHeading}>
				<h1>Оплата зарубежных счетов и контрактов</h1>
				<p>
					Представляем возможность оплатить зарубежные сервисы и продукты Оплата
					внешнеэкономических контрактов для Российских импортеров
				</p>
				<a href='#payment'>
					<Button>Оплатить</Button>
				</a>
			</div>
			<img src={banner} alt='Оплата зарубежных счетов и контрактов' />
			<div className={styles.logos}>
				<div>
					<img src={apple} alt='apple' />
					<img src={netflix} alt='netflix' />
					<img src={youtube} alt='youtube' />
					<img src={faceapp} alt='faceapp' />
					<img src={patreon} alt='patreon' />
					<img src={playStation} alt='playStation' />
					<img src={shopify} alt='shopify' />
				</div>
				<div>
					<img src={hetzer} alt='hetzer' />
					<img src={zoom} alt='zoom' />
					<img src={amazon} alt='amazon' />
					<img src={googleDrive} alt='googleDrive' />
					<img src={airbnb} alt='airbnb' />
					<img src={microsoft} alt='microsoft' />
				</div>
			</div>
			<div>
				<div className={styles.bannerCardsContainer}>
					{paymentData.map((item, index) => (
						<div key={v4()}>
							<div className={styles.index}>
								<p>{index + 1}</p>
							</div>
							<p className={styles.text}>{item}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
const AutoPayment = () => {
	return (
		<section className={styles.autoPayment}>
			<div>
				<div>
					<h1>Настройте автоплатеж на нужный Вам сервис</h1>
					<p>
						Мы ежемесячно автоматически будем пополнять сервис с Вашего баланса
					</p>
				</div>

				<Button>Подключить</Button>
			</div>
			<img src={autoPayment} alt='Настройте автоплатеж' />
		</section>
	);
};
const Clearing = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const classes = useModalStyles();
	const ref = useOutsideClick(() => setIsModalOpened(false));
	return (
		<>
			<section className={styles.clearing}>
				<h1>
					Клиринговые <br /> операции
				</h1>
				<p>
					Оплата внешнеэкономических контрактов для российских импортеров быстро
					и дешево
				</p>
				<div className={styles.clearingCardsContainer}>
					<div className={styles.clearingCard}>
						<div className={styles.cardHead}>
							<h1>Для Россиян</h1>
							<img src={clearing1} alt='Для Россиян' />
						</div>
						<p>
							Секретарь Олег ответит на звонки спамеров и мошенников вместо вас.
							Виртуальный номер можно использовать для регистраций на сайтах,
							чтобы не раскрывать основной
						</p>
						<Button onClick={() => setIsModalOpened(true)}>
							Отправить заявку
						</Button>
					</div>
					<div className={styles.clearingCard}>
						<div className={styles.cardHead}>
							<h1>Для иностранцев</h1>
							<img src={clearing2} alt='Для иностранцев' />
						</div>
						<p>
							Знаем, как неприятно приезжать в офис оператора по пустякам.
							Поэтому любой вопрос круглосуточно помогут решить в чате
							приложения или по телефону
						</p>
						<Button onClick={() => setIsModalOpened(true)}>
							Отправить заявку
						</Button>
					</div>
				</div>
			</section>
			{isModalOpened && (
				<section className={styles.modal}>
					<div ref={ref}>
						<div className={styles.modalHead}>
							<h1>Оформление карты</h1>
							<button
								className={styles.cross}
								onClick={() => setIsModalOpened(false)}
							>
								<div></div>
								<div></div>
							</button>
						</div>
						<div className={styles.modalBody}>
							<form className={styles.modalForm}>
								<div>
									<input type='text' placeholder={'Регион*'} />

									<input type='text' placeholder={'Сумма*'} />
									<div className={styles.upload}>
										<input
											accept='*'
											className={classes.input}
											id='contained-button-file'
											multiple
											type='file'
										/>
										<label htmlFor='contained-button-file'>
											Загрузить файл (pdf)
											<button>Выбрать файл</button>
										</label>
									</div>
									<p>
										Ваша заявка сохраниться в личном кабинете внутри которой
										можно будет обсудить нюансы с Вашим персональным менеджером
									</p>
								</div>

								<Button>Отправить</Button>
							</form>
						</div>
					</div>
				</section>
			)}
		</>
	);
};
const Payment = () => {
	return (
		<MainContainer>
			<Banner />
			<PaymentService />
			<AutoPayment />
			<Clearing />
			<Missing />
		</MainContainer>
	);
};

export default Payment;
