import styles from './Button.module.scss';
import classNames from 'classnames';
const Button = ({ children, style, onClick }) => {
	return (
		<button onClick={onClick} className={classNames(styles.button, style)}>
			{children}
		</button>
	);
};

export default Button;
