import classNames from 'classnames';
import styles from '../../screens/ForTourists/ForTourists.module.scss';
import Button from '../Button/Button';
import React from 'react';

const Card = ({ title, text, img, className }) => {
	return (
		<div className={classNames(styles.card, className)}>
			<div>
				<h3>{title}</h3>
				<p>{text}</p>
			</div>
			<img src={img} alt={title} />
			<Button>Подробнее</Button>
		</div>
	);
};
export default Card;
