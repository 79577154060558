import MainContainer from '../../containers/MainContainer/MainContainer';
import banner from '../../assets/images/Vacancies/Banner.png';
import form from '../../assets/images/Vacancies/Form.png';
import benefit1 from '../../assets/images/Vacancies/benefits/benefit1.png';
import benefit2 from '../../assets/images/Vacancies/benefits/benefit2.png';
import benefit3 from '../../assets/images/Vacancies/benefits/benefit3.png';
import condition1 from '../../assets/images/Vacancies/conditions/condition1.png';
import condition2 from '../../assets/images/Vacancies/conditions/condition2.png';
import condition3 from '../../assets/images/Vacancies/conditions/condition3.png';
import { v4 } from 'uuid';
import React from 'react';
import { vacanciesData } from '../../core/data/vacanciesData/vacanciesData';
import Button from '../../ui/Button/Button';
import accordionStyles from '../CardRegistration/CardRegistration.module.scss';
import styles from './Vacancies.module.scss';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';

import chevron from '../../assets/images/cardRegistration/chevron.svg';
import { useStyles } from '../../core/hooks/useStyles';

const Banner = () => {
	return (
		<section className={styles.banner}>
			<div>
				<h1>Вакансии</h1>
				<p>Присоединяйся к нашей команде</p>
			</div>
			<img src={banner} alt='Вакансии' />
		</section>
	);
};
const Benefits = () => {
	return (
		<section className={styles.benefits}>
			<div className={styles.benefitsCard}>
				<img src={benefit1} alt='Современная компания' />
				<div>
					<h3>Современная компания</h3>
					<p>Без бюрократии</p>
				</div>
			</div>
			<div className={styles.benefitsCard}>
				<img src={benefit2} alt='Работа с комфортом' />
				<div>
					<h3>Работа с комфортом</h3>
					<p>Из дома или в офисе</p>
				</div>
			</div>
			<div className={styles.benefitsCard}>
				<img src={benefit3} alt='Достойная зарплата' />
				<div>
					<h3>Достойная зарплата</h3>
					<p>Даже без опыта</p>
				</div>
			</div>
		</section>
	);
};

const GetJob = () => {
	return (
		<section className={styles.getJobContainer}>
			<h1>Как начать работать</h1>
			<div className={styles.getJob}>
				{vacanciesData.map((item, index) => (
					<div key={v4()}>
						<div className={styles.index}>
							<p>{index + 1}</p>
						</div>
						<p className={styles.text}>{item}</p>
					</div>
				))}
			</div>
		</section>
	);
};

const AvailableJobs = () => {
	return (
		<section className={styles.availableJobs}>
			<h1>Вакансии </h1>
			<div className={styles.job}>
				<div className={styles.jobContent}>
					<div>
						<h3>Менеджер по продажам B2B. С опытом от 6 месяцев</h3>
						<p>
							Активные телефонные продажи продуктов и услуг Тинькофф юридическим
							лицам. Сопровождение клиента от оформления заявки до первого
							использования продукта
						</p>
					</div>
					<div className={styles.jobConditions}>
						<div>Из дома</div>
						<div>Опыт от 6 мес.</div>
					</div>
				</div>
				<div className={styles.salary}>
					<h3>80 000 — 150 000₽</h3>
					<Button>Откликнуться</Button>
				</div>
			</div>
			<Button>Показать больше</Button>
		</section>
	);
};

const Form = () => {
	return (
		<section className={styles.info}>
			<form>
				<h1>Заполните информацию</h1>
				<label>
					<input type='text' placeholder={'Фамилия, имя и отчество*'} />
				</label>
				<label>
					<input type='text' placeholder={'Город*'} />
				</label>
				<label>
					<input type='text' placeholder={'Дата рождения*'} />
				</label>
				<div>
					<label>
						<input type='text' placeholder={'Телефон*'} />
					</label>
					<label>
						<input type='text' placeholder={'E-mail*'} />
					</label>
				</div>
				<div className={styles.border}></div>
				<Button>Отправить</Button>
			</form>
			<img src={form} alt='Заполните информацию' />
		</section>
	);
};

const Conditions = () => {
	return (
		<section className={styles.conditions}>
			<h1>Выбирайте комфортные условия</h1>
			<div className={styles.conditionsContainer}>
				<div className={styles.conditionsCard}>
					<img src={condition1} alt='Доход без ограничений' />
					<div>
						<h3>Доход без ограничений</h3>
						<p>
							Финансовая стабильность и возможность получать повышенное
							вознаграждение за дополнительные часы работы или активную продажу
							продуктов
						</p>
					</div>
				</div>
				<div className={styles.conditionsCard}>
					<img src={condition2} alt='Удобный график работы' />
					<div>
						<h3>Удобный график работы</h3>
						<p>
							Работайте 5/2, 2/2 или по гибкому графику с почасовой оплатой —
							удаленно или в офисе. Если совмещаете с учебой, то подберем
							подходящую вакансию
						</p>
					</div>
				</div>
				<div className={styles.conditionsCard}>
					<img src={condition3} alt='Можно без опыта' />
					<div>
						<h3>Доход без ограничений</h3>
						<p>
							Главное — коммуникабельность, активность и желание работать,
							остальному научим. Начните свою карьеру в крупной и современной
							компании
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};
const Chevron = () => <img src={chevron} alt='' />;
const Faq = () => {
	const classes = useStyles();

	return (
		<section className={accordionStyles.faq}>
			<h1>Ответы на частые вопросы</h1>
			<div className={accordionStyles.accordionContainer}>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какое образование требуется?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какой график?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Есть ли ограничения по возрасту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как проходит обучение?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что понадобится для работы или оказания услуг?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что если я из другой страны?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Можно ли совмещать с учебой или занятостью в другой компании?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какие есть бонусы и скидки?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Что делать, если вакансия есть на hh.ru, но нет в списке на этой
							странице?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Где посмотреть вакансии в IT и в бизнес направлениях?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</section>
	);
};
const Vacancies = () => {
	return (
		<MainContainer>
			<Banner />
			<Benefits />
			<GetJob />
			<AvailableJobs />
			<Form />
			<Conditions />
			<Faq />
		</MainContainer>
	);
};

export default Vacancies;
