export const siteNavigation = [
	{
		label: 'Главная',
		link: '/'
	},
	{
		label: 'Продукт',
		link: '/'
	},
	{
		label: 'О нас',
		link: '/'
	},
	{
		label: 'Контакты',
		link: '/'
	},
	{
		label: 'Как работает',
		link: '/'
	}
];
export const otherNavigation = [
	{
		label: 'Карты',
		link: '/'
	},
	{
		label: 'Сервисы',
		link: '/'
	},
	{
		label: 'Туристам',
		link: '/ForTourists'
	},
	{
		label: 'Партнерам',
		link: '/'
	}
];
