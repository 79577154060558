import React from 'react';
import MainContainer from '../../containers/MainContainer/MainContainer';
import Missing from '../../ui/Missing/Missing';
import Button from '../../ui/Button/Button';
import card1 from '../../assets/images/ForTousists/card1.png';
import card2 from '../../assets/images/ForTousists/card2.png';
import card3 from '../../assets/images/ForTousists/card3.png';
import card4 from '../../assets/images/ForTousists/card4.png';
import styles from './ForTourists.module.scss';
import coral from '../../assets/images/ForTousists/Coral.png';
import hotDeals from '../../assets/images/ForTousists/hotDeals.png';
import banner from '../../assets/images/ForTousists/banner.png';
import Card from '../../ui/Card/Card';
const Banner = () => {
	return (
		<section className={styles.banner}>
			<div>
				<h1>Наши продукты для Вас</h1>
				<p>Наши продукты для российских и иностранных туристов</p>
			</div>
			<img
				src={banner}
				alt='Наши продукты для российских и иностранных туристов'
			/>
		</section>
	);
};

const CardContainer = () => {
	return (
		<section className={styles.cardContainer}>
			<div>
				<Card
					title={'Аренда банковской карты'}
					text={
						'Возможность оформить в аренду банковскую карту на период туристической поездки'
					}
					img={card1}
					className={styles.card1}
				/>
				<Card
					title={'Оформление банковской карты'}
					text={
						'Оформление зарубежной банковской карты для использования во время отпуска'
					}
					img={card2}
					className={styles.card2}
				/>
			</div>
			<div>
				<Card
					title={'Оплата туристической поездки за счет компании'}
					text={
						'Возможность оплатить туроператору со счета российской компании'
					}
					img={card3}
					className={styles.card3}
				/>
				<Card
					title={'Оплата счетов во время отпуска'}
					text={
						'Оплата небольших счетов зарубежным сервисам, развлечение, размещение и т.д.'
					}
					img={card4}
					className={styles.card4}
				/>
			</div>
		</section>
	);
};

const Partners = () => {
	return (
		<section className={styles.partners}>
			<h1>Наши партнеры</h1>
			<div>
				<div>
					<h3>Coraltravel</h3>
					<a href='https://www.coral.ru' target={'_blank'} rel={'noreferrer'}>
						https://www.coral.ru
					</a>
					<img src={hotDeals} alt='Горячие туры' />
					<Button>Подробнее</Button>
				</div>
				<img src={coral} alt='Coraltravel' />
			</div>
		</section>
	);
};
const ForTourists = () => {
	return (
		<MainContainer>
			<Banner />
			<CardContainer />
			<Partners />
			<Missing />
		</MainContainer>
	);
};

export default ForTourists;
