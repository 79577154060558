import DineroLogo from '../../ui/Logo/DineroLogo/DineroLogo';
import youtube from '../../assets/images/logos/youtube.png';
import ok from '../../assets/images/logos/ok.png';
import twitter from '../../assets/images/logos/twitter.png';
import telegram from '../../assets/images/logos/telegram.png';
import vk from '../../assets/images/logos/vk.png';
import {
	footerContactsData,
	footerData
} from '../../core/data/footerData/footerData';
import styles from './Footer.module.scss';
import { v4 } from 'uuid';

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<section className={styles.logos}>
				<div className={styles.logosWrapper}>
					<DineroLogo />
					<div className={styles.info}>
						<nav>
							<ul>
								<li>
									<a href='/'>
										<img src={vk} alt={'vk'} />
									</a>
								</li>
								<li>
									<a href='/'>
										<img src={ok} alt={'ok'} />
									</a>
								</li>
								<li>
									<a href='/'>
										<img src={twitter} alt={'twitter'} />
									</a>
								</li>
								<li>
									<a href='/'>
										<img src={youtube} alt={'youtube'} />
									</a>
								</li>
								<li>
									<a href='/'>
										<img src={telegram} alt={'telegram'} />
									</a>
								</li>
							</ul>
						</nav>
						<div className={styles.phone}>
							<a href={'tel:8 888 888-888-8'}>8 888 888-888-8</a>
							<p>Для звонков по России</p>
						</div>
					</div>
				</div>
			</section>
			<section className={styles.contacts}>
				<div className={styles.contactsWrapper}>
					{footerData.map(item => (
						<div className={styles.cardContainer} key={v4()}>
							<h3>{item.title}</h3>
							<ul>
								{item.data.map(dataItem => (
									<li key={v4()}>
										<a href={dataItem.link}>{dataItem.label}</a>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
				<h1 className={styles.heading}>Контакты</h1>
				<div className={styles.layoutContactsWrapper}>
					{footerContactsData.map(item => (
						<div className={styles.cardContainer} key={v4()}>
							{item.title === 'Офис' ? (
								<div>
									<h4>{item.title}</h4>
									{item.data.map(dataItem => (
										<div key={v4()}>
											<p className={styles.address}>{dataItem.address}</p>
											<h4>Телефон</h4>
											{dataItem.contactPhone && (
												<a
													href={`tel:${dataItem.contactPhone}`}
													className={styles.contactInfo}
												>
													{dataItem.contactPhone}
												</a>
											)}
											<h4>Пресс служба</h4>
											{dataItem.contactEmail && (
												<a
													href={`email:${dataItem.contactEmail}`}
													className={styles.contactInfo}
												>
													{dataItem.contactEmail}
												</a>
											)}
										</div>
									))}
								</div>
							) : (
								<>
									<h4>{item.title}</h4>
									{item.data.map(dataItem => (
										<div key={v4()}>
											<h5>{dataItem.title}</h5>
											{dataItem.contactPhone && (
												<a
													href={`tel:${dataItem.contactPhone}`}
													className={styles.contactInfo}
												>
													{dataItem.contactPhone}
												</a>
											)}
											{dataItem.contactEmail && (
												<a
													href={`email:${dataItem.contactEmail}`}
													className={styles.contactInfo}
												>
													{dataItem.contactEmail}
												</a>
											)}
											{dataItem.free && (
												<p className={styles.free}>Бесплатно</p>
											)}
										</div>
									))}
								</>
							)}
						</div>
					))}
				</div>
			</section>
		</footer>
	);
};

export default Footer;
