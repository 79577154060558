import React from 'react';
import MainContainer from '../../containers/MainContainer/MainContainer';
import styles from './News.module.scss';
const News = () => {
	return (
		<MainContainer>
			<section className={styles.container}>
				<h1>
					Итоги креатонов для начинающих дизайнеров в рамках "Московской недели
					интерьера и дизайна"
				</h1>
				<p className={styles.date}>20 апреля 2023</p>
				<div>
					<p>
						В Москве подведены итоги студенческих креатонов, которые проходили в
						рамках образовательной программы "Московской недели интерьера и
						дизайна". Организаторы получили 313 заявок, из которых было отобрано
						90 проектов. Креатонам предшествовало проведение роад-шоу и
						бизнес-практикумов в 16 вузах и колледжах Москвы, в которых приняло
						участие более 1000 человек. В защите проектов приняло участие более
						300 студентов из 90 студенческих команд. В работе образовательной
						программы в качестве лекторов, кураторов и членов жюри приняло
						участие 49 экспертов - лучших специалистов отрасли.
						<br />
						<br />
						Куратор-наставник креатонов Наталья Преображенская, дизайнер,
						искусствовед, владелец интерьерной студии "Уютная квартира" и бюро
						премиальных интерьеров TREND'UP, отметила: "Сейчас очень легко
						учиться новому, поэтому задача креатонов в рамках образовательной
						программы на Недели интерьеров и дизайна состояла и в возможности
						раскрыть свой творческий потенциал студентам, и увидеть совершенно
						новое для себя, так как защиты ежедневно проходили по диаметрально
						разным задачам. Студенты из разных вузов имели возможность
						знакомиться с работами друг друга, посещая креатоны, что приведет
						безусловно к новым прорывам, коллаборации и креативу".
						<br />
						Победителем, занявшим 1-е место в креатоне "Городская среда.
						Уникальные средовые объекты. Кинетическая скульптура в
						благоустройстве" стала команда из РГХПУ им. С.Г.Строганова Строга
						women за проект кинетической скульптуры "Символ жизни". Местом
						расположения объекта авторы предложили ИНТЦ МГУ "Воробьевы горы".
						Это символичная скульптура. Контрастные линии модели повторяют
						знаковую и важную форму - спирального элемента. "Символ жизни"
						приводится в действие методом механизма, подобному тому, который
						стоит в часах. Таким образом, мы не только создаем символику,
						связанную с течением времени, но и функционально оснащаем нашу
						скульптуру надежным механизмом, который помогает вращать спираль и
						оболочку по часовой и против часовой стрелке. Авторы: Окарова
						Вероника, Ларина Виктория, Мусиенко Ирина.
						<br />
						<br />В креатоне "Дизайн и архитектура интерьера: общественные и
						детские игровые пространства" 1-е место было отдано проекту
						общественно мультимедийного пространства для подростков от 14-17 лет
						TeenPlace от РГХПУ им. С.Г. Строганова, представленному командой
						DVision Авторы: Анастасия Мухтарова, Юлия Шмалько, Анастасия
						Арончик, Степан Летяев о проекте: "Мы выводим социальную сеть ВК из
						виртуального мира в реальный, создавая физическое место, где
						подростки могут общаться в живую. Концепция "TeenPlace" -
						многогранность. Здесь можно развивать интеллект или физические
						навыки, общаться со сверстниками или побыть в одиночестве, проявить
						активность или отдохнуть, где 6 граней - как 6 потребностей
						личности".
					</p>
				</div>
			</section>
		</MainContainer>
	);
};

export default News;
