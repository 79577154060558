import { RouterProvider } from 'react-router-dom';
import { router } from './core/utils/router/router';
import Header from './layouts/Header/Header';
import Footer from './layouts/Footer/Footer';
import MobileMenu from './layouts/MobileMenu/MobileMenu';

const App = () => {
	return (
		<>
			<Header />
			<MobileMenu />
			<RouterProvider router={router} />
			<Footer />
		</>
	);
};

export default App;
