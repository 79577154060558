import React, { useState } from 'react';
import MainContainer from '../../containers/MainContainer/MainContainer';
import ServiceForm from '../../ui/ServiceForm/ServiceForm';
import styles from './AllProducts.module.scss';
import Search from '../../ui/Search/Search';
import Button from '../../ui/Button/Button';
import { allProductsData } from '../../core/data/allProducts/allProductsData';
import { useOutsideClick } from '../../core/hooks/useClickOutside';
const AllProducts = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	const ref = useOutsideClick(() => setIsModalOpened(false));
	return (
		<MainContainer>
			<section className={styles.cards}>
				<div>
					<h1>Оплата сервисов и продуктов</h1>
					<p>
						За последние три года клиенты оформили более 16 млн полисов в
						Тинькофф Страховании
					</p>
				</div>
				<Search className={styles.inputShadow} />

				<div className={styles.cadrsWrapper}>
					<div className={styles.cardContainer}>
						{allProductsData.map(item => (
							<div className={styles.card}>
								<h3>{item.title}</h3>
								<img src={item.img} alt={item.title} />
								<Button onClick={() => setIsModalOpened(true)}>Выбрать</Button>
							</div>
						))}
					</div>
					<Button>Показать больше</Button>
				</div>
			</section>
			<section className={styles.serviceFormContainer}>
				<ServiceForm />
			</section>
			{isModalOpened && (
				<section className={styles.modal}>
					<div ref={ref}>
						<div className={styles.modalHead}>
							<h1>Оплата Apple</h1>
							<button
								className={styles.cross}
								onClick={() => setIsModalOpened(false)}
							>
								<div></div>
								<div></div>
							</button>
						</div>
						<div className={styles.modalBody}>
							<form className={styles.modalForm}>
								<div>
									<input type='text' placeholder={'Фамилия*'} />
									<input type='text' placeholder={'Имя*'} />
									<input type='text' placeholder={'Отчество*'} />
									<input type='text' placeholder={'Телефон*'} />
									<input type='text' placeholder={'Адрес*'} />
									<input type='text' placeholder={'Регион*'} />
									<input type='text' placeholder={'Дата рождения*'} />
									<input type='text' placeholder={'E-mail*'} />
									<input type='text' placeholder={'Сумма пополнения*'} />
								</div>

								<Button>Оплатить</Button>
							</form>
						</div>
					</div>
				</section>
			)}
		</MainContainer>
	);
};

export default AllProducts;
