import { useState } from 'react';
import classNames from 'classnames';
import styles from '../../screens/CardRegistration/CardRegistration.module.scss';
import Button from '../Button/Button';

const Missing = () => {
	const [isTextareaOpened, setIsTextareaOpened] = useState(false);
	return (
		<section
			className={classNames(styles.missing, {
				[styles.activeMissing]: isTextareaOpened
			})}
		>
			{isTextareaOpened ? (
				<div className={styles.innerPadding}>
					<h1>Какой информации не хватило на сайте?</h1>
					<form>
						<textarea placeholder={'Введите свой ответ'}></textarea>
					</form>
					<Button onClick={() => setIsTextareaOpened(prev => !prev)}>
						Ответить
					</Button>
				</div>
			) : (
				<>
					<h1>Расскажите, чего вам не хватило на странице?</h1>
					<Button onClick={() => setIsTextareaOpened(prev => !prev)}>
						Ответить
					</Button>
				</>
			)}
		</section>
	);
};
export default Missing;
