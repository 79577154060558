import MainContainer from '../../containers/MainContainer/MainContainer';
import Missing from '../../ui/Missing/Missing';
import chevron from '../../assets/images/cardRegistration/chevron.svg';
import { useStyles } from '../../core/hooks/useStyles';
import accordionStyles from '../CardRegistration/CardRegistration.module.scss';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary, Slider } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import styles from './CardRent.module.scss';
import bannerCard from '../../assets/images/CardRent/bannerCard.png';
import Button from '../../ui/Button/Button';
import { forwardRef, useState } from 'react';
import { useOutsideClick } from '../../core/hooks/useClickOutside';
import classNames from 'classnames';
import cart from '../../assets/images/CardRent/cart.png';
import getCard from '../../assets/images/CardRent/getCard.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const BannerCard = ({ onClick }) => {
	return (
		<section className={styles.bannerCard}>
			<h1>Аренда банковской карты</h1>
			<p>Аренда банковской карты на время путешествия или командировки</p>
			<div className={styles.card}>
				<div className={styles.cardData}>
					<div className={styles.cardType}>
						<img src={cart} alt='Наша лучшая рекомендация' />
						<p>Наша лучшая рекомендация</p>
					</div>
					<div>
						<div>
							<h1 className={styles.title}>
								Аренда банковской карты для путешественников{' '}
							</h1>
							<p className={styles.description}>
								На время поездки предлагаем Вам воспользоваться арендой
								банковской карты действующий в данной стране
							</p>
						</div>
						<div className={styles.cardConditions}>
							<div>
								<h3 className={styles.conditionTitle}>До 700 000 &#8381;</h3>
								<p className={styles.conditionDescription}>Лимит по карте</p>
							</div>
							<div>
								<h3 className={styles.conditionTitle}>До 3% </h3>
								<p className={styles.conditionDescription}>Комиссия</p>
							</div>
							<div>
								<h3 className={styles.conditionTitle}>до 30 дней</h3>
								<p className={styles.conditionDescription}>
									Максимальный срок аренды
								</p>
							</div>
						</div>
						<div className={styles.getCard}>
							<Button onClick={onClick}>Оформить карту</Button>
						</div>
					</div>
				</div>
				<img
					src={bannerCard}
					alt={'Аренда банковской карты для путешественников '}
					className={styles.cardPhoto}
				/>
			</div>
		</section>
	);
};
const Modals = ({ isModalOpened, onClick }) => {
	const [activeIndex, setActiveIndex] = useState(1);

	const ref = useOutsideClick(onClick);
	return (
		<>
			{isModalOpened && (
				<section className={styles.modal}>
					<div ref={ref}>
						<div className={styles.modalHeader}>
							<div>
								<h1>Оформление карты</h1>
								<div className={styles.cross} onClick={onClick}>
									<div></div>
									<div></div>
								</div>
							</div>
							<div className={styles.nav}>
								<ul>
									<li
										className={classNames({ [styles.done]: activeIndex >= 1 })}
									>
										1
									</li>
									<li
										className={classNames({ [styles.done]: activeIndex >= 2 })}
									>
										2
									</li>
									<li
										className={classNames({ [styles.done]: activeIndex >= 3 })}
									>
										3
									</li>
									<li
										className={classNames({ [styles.done]: activeIndex >= 4 })}
									>
										4
									</li>
								</ul>
								<div className={styles.border}></div>
							</div>
						</div>
						<div className={styles.modalBody}>
							{activeIndex === 1 && (
								<>
									<div>
										<p>Вам потребуется</p>
										<ul>
											<li> Паспорт РФ (PDF)</li>
											<li> Заграничный паспорт (PDF)</li>
										</ul>
									</div>
									<p>
										Так же Вам потребуется скачать и заполнить указанные далее
										файлы.
									</p>
									<p>
										Если Вы зарегистрированный пользователь, то при прерывании
										заполнения формы, она будет автоматически сохранена в Вашем
										личном кабинете в разделе шаблоны
									</p>
									<Button onClick={() => setActiveIndex(2)}>Далее</Button>
								</>
							)}
							{activeIndex === 2 && (
								<>
									<form className={styles.modalForm}>
										<input type='text' placeholder={'Фамилия*'} />
										<input type='text' placeholder={'Имя*'} />
										<input type='text' placeholder={'Отчество*'} />
										<input type='text' placeholder={'Телефон*'} />
										<input type='text' placeholder={'Адрес*'} />
										<input type='text' placeholder={'Регион*'} />
										<input type='text' placeholder={'Дата рождения*'} />
										<input type='text' placeholder={'E-mail*'} />
									</form>
									<div className={styles.buttonContainer}>
										<Button onClick={() => setActiveIndex(1)}>Назад</Button>
										<Button onClick={() => setActiveIndex(3)}>Далее</Button>
									</div>
								</>
							)}
							{activeIndex === 3 && (
								<>
									<form className={styles.modalForm}>
										<input type='text' placeholder={'Адрес регистрации*'} />
										<div className={styles.inputContainer}>
											<input type='text' placeholder={'Серия паспорта*'} />
											<input type='text' placeholder={'Номер паспорта*'} />
										</div>
										<input type='text' placeholder={'Когда выдан паспорт*'} />
										<input type='text' placeholder={'Кем выдан паспорт*'} />
									</form>
									<div className={styles.buttonContainer}>
										<Button onClick={() => setActiveIndex(2)}>Назад</Button>
										<Button onClick={() => setActiveIndex(4)}>Далее</Button>
									</div>
								</>
							)}
							{activeIndex === 4 && (
								<>
									<form className={styles.modalForm}>
										<input type='text' placeholder={'Сумма лимита по карте*'} />
										<input type='text' placeholder={'Дата аренды карты*'} />
										<input type='text' placeholder={'Страна аренды карты*'} />
										<div className={styles.upload}>
											<label>
												<input multiple type='file' />
												Загрузить договор*
												<button>Выбрать файл</button>
											</label>
										</div>
										<div className={styles.mobileLink}>
											<p>Ссылка на мобильное приложение банка</p>
											<a href='https://www.coral.ru/?ysclid=lh11g105ac430045117'>
												https://www.coral.ru/?ysclid=lh11g105ac430045117
											</a>
										</div>
									</form>
									<div className={styles.buttonContainer}>
										<Button onClick={() => setActiveIndex(3)}>Назад</Button>
										<Button>Отправить</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</section>
			)}
		</>
	);
};
const Cards = () => {
	return (
		<section className={styles.cards}>
			<h1>Получите карту уже сегодня</h1>
			<div className={styles.bannerCardsContainer}>
				<div>
					<div className={styles.index}>
						<p>1</p>
					</div>
					<p className={styles.text}>
						Зарегистрируйтесь.
						<br /> Заполните необходимые документы.
					</p>
				</div>
				<div>
					<div className={styles.index}>
						<p>2</p>
					</div>
					<p className={styles.text}>
						Выберите необходимые параметры карты.
						<br /> Получите одобрение системы
					</p>
				</div>
				<div>
					<div className={styles.index}>
						<p>3</p>
					</div>
					<p className={styles.text}>
						Получите необходимую
						<br />
						Вам карту
					</p>
				</div>
			</div>
		</section>
	);
};
const Chevron = () => <img src={chevron} alt='' />;
const Faq = () => {
	const classes = useStyles();

	return (
		<section className={accordionStyles.faq}>
			<h1>Ответы на частые вопросы</h1>
			<div className={accordionStyles.accordionContainer}>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Какие преимущества у кредитной карты перед кредитом?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как покупать в рассрочку по кредитной карте?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как снять наличные деньги с кредитной карты?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как начисляется кэшбэк за траты по кредитной карте?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</section>
	);
};
const Rent = () => {
	const [selectedDate, setSelectedDate] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(null);
	const onChange = dates => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const years = [2022.2023];

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className='example-custom-input' onClick={onClick} ref={ref}>
			{value}
		</button>
	));
	return (
		<section className={styles.rent}>
			<div>
				<div>
					<p>Укажите даты аренды</p>
					{
						<DatePicker
							selected={startDate}
							onChange={date => setStartDate(date)}
							startDate={startDate}
							endDate={endDate}
							selectsRange
							customInput={<ExampleCustomInput />}
							inline
							renderCustomHeader={({
								date,

								changeMonth,
								decreaseMonth,
								increaseMonth,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled
							}) => (
								<div
									style={{
										margin: 10,
										display: 'flex',
										justifyContent: 'center'
									}}
								>
									<button
										onClick={decreaseMonth}
										disabled={prevMonthButtonDisabled}
									>
										{'<'}
									</button>

									<select
										value={date}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map(option => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>

									<button
										onClick={increaseMonth}
										disabled={nextMonthButtonDisabled}
									>
										{'>'}
									</button>
								</div>
							)}
						/>
					}
				</div>
				<div>
					<p>Комиссионное вознаграждение </p>
					<h3>5%</h3>
					<div>
						<span></span>
					</div>
				</div>

				<img src={getCard} alt='Укажите даты аренды' />
			</div>
			<div>
				<p>Желаемый кредитный лимит</p>
				<Slider aria-label='Temperature' defaultValue={30} color='secondary' />
			</div>
		</section>
	);
};
const CardRent = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);
	return (
		<MainContainer>
			<BannerCard onClick={() => setIsModalOpened(true)} />
			<Modals
				isModalOpened={isModalOpened}
				onClick={() => setIsModalOpened(false)}
			/>
			<Cards />
			<Rent />
			<Faq />
			<Missing />
		</MainContainer>
	);
};

export default CardRent;
