import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isMenuActive: false,
	isMobileMenuActive: false,
	id: null
};

const MenuSlice = createSlice({
	name: 'MenuSlice',
	initialState,
	reducers: {
		setMenuActive(state, action) {
			state.isMenuActive = action.payload;
		},
		setMenuMobileActive(state, action) {
			state.isMobileMenuActive = action.payload;
		},
		setId(state, action) {
			state.id = action.payload;
		}
	}
});

export const { setMenuActive, setMenuMobileActive, setId } = MenuSlice.actions;
export default MenuSlice.reducer;
