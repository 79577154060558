import cardSet from '../../assets/images/cardRegistration/cardSet.png';
import styles from './CardRegistration.module.scss';
import Button from '../../ui/Button/Button';
import {
	bannerData,
	cards
} from '../../core/data/cardRegistrationData/cardRegistrationData';
import plane from '../../assets/images/cardRegistration/plane.png';
import car from '../../assets/images/cardRegistration/car.png';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionSummary } from '@mui/material';
import chevron from '../../assets/images/cardRegistration/chevron.svg';
import { v4 } from 'uuid';
import { useState } from 'react';
import classNames from 'classnames';
import MainContainer from '../../containers/MainContainer/MainContainer';
import { useOutsideClick } from '../../core/hooks/useClickOutside';
import Missing from '../../ui/Missing/Missing';
import { useStyles } from '../../core/hooks/useStyles';

const Chevron = () => <img src={chevron} alt='' />;
const Faq = () => {
	const classes = useStyles();

	return (
		<section className={styles.faq}>
			<h1>Ответы на частые вопросы</h1>
			<div className={styles.accordionContainer}>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>{' '}
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion className={classes.root}>
					<AccordionSummary
						expandIcon={<Chevron />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant={'h3'} className={classes.question}>
							Как открыть кредитную карту?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography className={classes.answer}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
							eget.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</section>
	);
};
const Cards = ({ heading, onClick }) => {
	return (
		<>
			<section className={styles.cards}>
				<h1>{heading}</h1>

				{cards.map(card => (
					<div className={styles.card} key={v4()}>
						<div className={styles.cardData}>
							<div className={styles.cardType}>
								{card.type === 'Для путешествий' ? (
									<>
										<img src={plane} alt='Для путешествий' />
										<p>Для путешествий</p>
									</>
								) : (
									<>
										<img src={car} alt='Для автомобилистов' />
										<p>Для автомобилистов</p>
									</>
								)}
							</div>
							<div>
								<div>
									<h1 className={styles.title}>{card.title}</h1>
									<p className={styles.description}>{card.description}</p>
								</div>
								<div className={styles.cardConditions}>
									<div>
										<h3 className={styles.conditionTitle}>
											До{' '}
											{card.limit
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
											&#8381;
										</h3>
										<p className={styles.conditionDescription}>
											Кредитный лимит
										</p>
									</div>
									<div>
										<h3 className={styles.conditionTitle}>
											До {card.cashback}% кэшбек
										</h3>
										<p className={styles.conditionDescription}>
											{card.type === 'Для путешествий'
												? 'За траты на путешествия'
												: 'За траты на автомобиль'}
										</p>
									</div>
									<div>
										<h3 className={styles.conditionTitle}>
											{card.serviceCost} &#8381;
										</h3>
										<p className={styles.conditionDescription}>
											{card.serviceType === 'Monthly'
												? 'С возможностью не платить'
												: 'Стоимость обслуживания'}
										</p>
									</div>
								</div>
								<div className={styles.getCard}>
									<Button onClick={onClick}>Оформить карту</Button>
									<a href={card.link}>Подробнее</a>
								</div>
							</div>
						</div>
						<img
							src={card.image}
							alt={card.title}
							className={styles.cardPhoto}
						/>
					</div>
				))}
			</section>
		</>
	);
};

const Modals = ({ isModalOpened, onClick }) => {
	const [activeIndex, setActiveIndex] = useState(1);
	const ref = useOutsideClick(onClick);
	return (
		<>
			{isModalOpened && (
				<section className={styles.modal}>
					<div ref={ref}>
						<div className={styles.modalHeader}>
							<div>
								<h1>Оформление карты</h1>
								<div className={styles.cross} onClick={onClick}>
									<div></div>
									<div></div>
								</div>
							</div>
							<div className={styles.nav}>
								<ul>
									<li
										className={classNames({ [styles.done]: activeIndex >= 1 })}
									>
										1
									</li>
									<li
										className={classNames({ [styles.done]: activeIndex >= 2 })}
									>
										2
									</li>
									<li
										className={classNames({ [styles.done]: activeIndex >= 3 })}
									>
										3
									</li>
								</ul>
								<div className={styles.border}></div>
							</div>
						</div>
						<div className={styles.modalBody}>
							{activeIndex === 1 && (
								<>
									<div>
										<p>Вам потребуется</p>
										<ul>
											<li> Паспорт РФ (PDF)</li>
											<li> Заграничный паспорт (PDF)</li>
										</ul>
									</div>
									<p>
										Так же Вам потребуется скачать и заполнить указанные далее
										файлы.
									</p>
									<p>
										Если Вы зарегистрированный пользователь, то при прерывании
										заполнения формы, она будет автоматически сохранена в Вашем
										личном кабинете в разделе шаблоны
									</p>
									<Button onClick={() => setActiveIndex(2)}>Далее</Button>
								</>
							)}
							{activeIndex === 2 && (
								<>
									<form className={styles.modalForm}>
										<input type='text' placeholder={'Фамилия*'} />
										<input type='text' placeholder={'Имя*'} />
										<input type='text' placeholder={'Отчество*'} />
										<input type='text' placeholder={'Телефон*'} />
										<input type='text' placeholder={'Адрес*'} />
										<input type='text' placeholder={'Регион*'} />
										<input type='text' placeholder={'Дата рождения*'} />
										<input type='text' placeholder={'E-mail*'} />
									</form>
									<div className={styles.buttonContainer}>
										<Button onClick={() => setActiveIndex(1)}>Назад</Button>
										<Button onClick={() => setActiveIndex(3)}>Далее</Button>
									</div>
								</>
							)}
							{activeIndex === 3 && (
								<>
									<form className={styles.modalForm}>
										<input type='text' placeholder={'Адрес регистрации*'} />
										<div className={styles.inputContainer}>
											<input type='text' placeholder={'Серия паспорта*'} />
											<input type='text' placeholder={'Номер паспорта*'} />
										</div>
										<input type='text' placeholder={'Когда выдан паспорт*'} />
										<input type='text' placeholder={'Кем выдан паспорт*'} />
										<div className={styles.download}>
											<p>
												Документы банка <a href='/'>Скачать</a>
											</p>
										</div>
										<div className={styles.upload}>
											<input id='contained-button-file' multiple type='file' />
											<label htmlFor='contained-button-file'>
												Загрузить договор*
												<button>Выбрать файл</button>
											</label>
										</div>
									</form>
									<div className={styles.buttonContainer}>
										<Button onClick={() => setActiveIndex(2)}>Назад</Button>
										<Button>Отправить</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</section>
			)}
		</>
	);
};
const Replenishment = () => {
	return (
		<section className={styles.replenishment}>
			<div>
				<h1>Пополнение счета банковской карты</h1>
				<p>
					Из личного кабинета Вы всегда можете пополнить счет своей банковской
					карты
				</p>
			</div>
			<Button>Пополнить</Button>
		</section>
	);
};

const Banner = () => {
	return (
		<section className={styles.banner}>
			<div>
				<h1 className={styles.heading}>
					Оформление зарубежных банковских карт
				</h1>
				<p className={styles.subHeading}>
					Услуга по оформлению банковской карты иностранного государства для
					туристов желающих поехать зарубеж
				</p>
				<img src={cardSet} alt='Банковские карты' />
			</div>
			<div className={styles.bannerCardsContainer}>
				{bannerData.map((item, index) => (
					<div key={v4()}>
						<div className={styles.index}>
							<p>{index + 1}</p>
						</div>
						<p className={styles.text}>{item}</p>
					</div>
				))}
			</div>
		</section>
	);
};

const CardRegistration = () => {
	const [isModalOpened, setIsModalOpened] = useState(false);

	return (
		<MainContainer>
			<Banner />
			<Replenishment />
			<Cards
				heading={'Выберите банковскую карту'}
				onClick={() => setIsModalOpened(true)}
			/>
			<Cards
				heading={'Оформление российских банковских карт'}
				onClick={() => setIsModalOpened(true)}
			/>
			<Modals
				isModalOpened={isModalOpened}
				onClick={() => setIsModalOpened(false)}
			/>
			<Faq />
			<Missing />
		</MainContainer>
	);
};

export default CardRegistration;
