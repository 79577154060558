export const footerData = [
	{
		title: 'Главная',
		data: [
			{ label: 'Почему Мы', link: '/' },
			{ label: 'Услуги и продукты', link: '/' },
			{ label: 'Как это работает', link: '/' },
			{ label: 'Как это работает', link: '/' },
			{ label: 'Актуально сейчас', link: '/' },
			{ label: 'Партнерам', link: '/' },
			{ label: 'Вопросы и ответы', link: '/' }
		]
	},
	{
		title: 'Туристам',
		data: [
			{ label: 'Продукты для туристов', link: '/' },
			{ label: 'Наши партнеры', link: '/' }
		]
	},
	{
		title: 'Аренда банковской карты',
		data: [
			{ label: 'Условия', link: '/' },
			{ label: 'Как арендовать', link: '/' },
			{ label: 'Арендовать карту', link: '/' },
			{ label: 'Вопросы и ответы', link: '/' }
		]
	},
	{
		title: 'Оформление банковской карты',
		data: [
			{ label: 'Как оформить', link: '/' },
			{ label: 'Все карты', link: '/' },
			{ label: 'Арендовать карту', link: '/' },
			{ label: 'Вопросы и ответы', link: '/' }
		]
	},
	{
		title: 'Оплата зарубежных счетов и контрактов',
		data: [
			{ label: 'Как оплатить сервис', link: '/' },
			{ label: 'Сервисы для оплаты', link: '/' },
			{ label: 'Клиринговые операции', link: '/' }
		]
	}
];
export const footerContactsData = [
	{
		title: 'Банковские карты',
		data: [
			{
				title: 'Вопросы по картам',
				contactPhone: '8 800 555-10-10',
				contactEmail: null,
				free: true
			},
			{
				title: 'Вопросы по доставке',
				contactPhone: '8 800 555-10-10',
				contactEmail: null,
				free: true
			},
			{
				title: 'Для звонков из других стран',
				contactPhone: '+7 495 648-11-11',
				contactEmail: null,
				free: false
			},
			{
				title: 'Электронная почта',
				contactPhone: null,
				contactEmail: 'credit@tinkoff.ru',
				free: false
			}
		]
	},
	{
		title: 'Для бизнеса',
		data: [
			{
				title: 'Для действующих бизнес-клиентов',
				contactPhone: '8 800 555-10-10',
				contactEmail: null,
				free: true
			},
			{
				title: 'Для звонков из других стран',
				contactPhone: '8 800 555-09-11',
				contactEmail: null,
				free: true
			},
			{
				title: 'Электронная почта',
				contactPhone: null,
				contactEmail: 'credit@tinkoff.ru',
				free: false
			}
		]
	},
	{
		title: 'Для партнеров',
		data: [
			{
				title: 'Выбрать направление партнерства',
				contactPhone: null,
				contactEmail: 'credit@tinkoff.ru',
				free: false
			},
			{
				title: 'Разместить рекламу',
				contactPhone: null,
				contactEmail: 'credit@tinkoff.ru',
				free: false
			},
			{
				title: 'Вопросы по сотрудничеству',
				contactPhone: '+7 495 648-11-11',
				contactEmail: null,
				free: false
			}
		]
	},
	{
		title: 'Офис',
		data: [
			{
				address:
					'Адрес: ул.Хуторская 2-я, д.38А, стр.26, Москва, 127287 Россия',
				contactPhone: '+7 495 648-11-11',
				contactEmail: 'credit@tinkoff.ru',
				free: false
			}
		]
	}
];
