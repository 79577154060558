import { createBrowserRouter } from 'react-router-dom';
import CardRegistration from '../../../screens/CardRegistration/CardRegistration';
import Payment from '../../../screens/Payment/Payment';
import Vacancies from '../../../screens/Vacancies/Vacancies';
import PartnerShip from '../../../screens/PartnerShip/PartnerShip';
import Error404 from '../../../screens/404/404';
import ForTourists from '../../../screens/ForTourists/ForTourists';
import AllProducts from '../../../screens/AllProducts/AllProducts';
import News from '../../../screens/News/News';
import Home from '../../../screens/Home/Home';
import Contacts from '../../../screens/Contacts/Contacts';
import CardRent from '../../../screens/CardRent/CardRent';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/Contacts',
		element: <Contacts />
	},
	{
		path: '/CardRent',
		element: <CardRent />
	},
	{
		path: '/CardRegistration',
		element: <CardRegistration />
	},
	{
		path: '*',
		element: <Error404 />
	},
	{
		path: '/Payment',
		element: <Payment />
	},
	{
		path: '/Vacancies',
		element: <Vacancies />
	},
	{
		path: '/PartnerShip',
		element: <PartnerShip />
	},
	{
		path: '/ForTourists',
		element: <ForTourists />
	},
	{
		path: '/AllProducts',
		element: <AllProducts />
	},
	{
		path: '/News',
		element: <News />
	}
]);
