import MainContainer from '../../containers/MainContainer/MainContainer';
import Button from '../../ui/Button/Button';
import banner from '../../assets/images/Home/banner.png';
import apple from '../../assets/images/companyLogos/apple.png';
import youtube from '../../assets/images/companyLogos/youtube.svg';
import netflix from '../../assets/images/companyLogos/netflix.svg';
import faceapp from '../../assets/images/companyLogos/faceapp.svg';
import patreon from '../../assets/images/companyLogos/patreon.svg';
import playStation from '../../assets/images/companyLogos/playStation.svg';
import shopify from '../../assets/images/companyLogos/shopify.svg';
import hetzer from '../../assets/images/companyLogos/hetzer.svg';
import zoom from '../../assets/images/companyLogos/zoom.svg';
import amazon from '../../assets/images/companyLogos/amazon.svg';
import googleDrive from '../../assets/images/companyLogos/googleDrive.svg';
import airbnb from '../../assets/images/companyLogos/airbnb.svg';
import microsoft from '../../assets/images/companyLogos/microsoft.png';
import Card from '../../ui/Card/Card';
import card1 from '../../assets/images/Home/card1.png';
import card2 from '../../assets/images/Home/card2.png';
import card3 from '../../assets/images/Home/card3.png';
import card4 from '../../assets/images/Home/card4.png';
import card5 from '../../assets/images/Home/card5.png';
import card6 from '../../assets/images/Home/card6.png';
import card7 from '../../assets/images/Home/card7.png';
import card8 from '../../assets/images/Home/card8.png';
import card9 from '../../assets/images/Home/card9.png';
import dinero from '../../assets/images/Home/dinero.png';
import FeedBack from '../../ui/FeedBack/FeedBack';
import { homeCardsData, homeData } from '../../core/data/homeData/homeData';
import clearing from '../../assets/images/Home/clearing.png';
import clearing1 from '../../assets/images/Home/clearing1.png';
import clearing2 from '../../assets/images/Home/clearing2.png';
import styles from './Home.module.scss';
import { v4 } from 'uuid';
import arrows from '../../assets/images/Home/arrows.png';
import chat from '../../assets/images/Home/chat.png';
import phone from '../../assets/images/Home/phone.png';
import googlePlay from '../../assets/images/Home/googlePlay.png';
import appStore from '../../assets/images/Home/appStore.png';
import qr from '../../assets/images/Home/qr.png';
import actual from '../../assets/images/Home/actual.png';
import like from '../../assets/images/Home/like.png';
import dislike from '../../assets/images/Home/dislike.png';
import handsmash from '../../assets/images/Home/handsmash.png';
import questions from '../../assets/images/Home/questions.png';
import Accordion from '@mui/material/Accordion';
import { AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import chevron from '../../assets/images/cardRegistration/chevron.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useStyles } from '../../core/hooks/useStyles';
const Banner = () => {
	return (
		<section className={styles.banner}>
			<h1>Оплата зарубежных счетов</h1>
			<p>
				Удобная и быстрая оплата зарубежных сервисов, счетов и интернет покупок
				зарубежных сервисов и магазинов
			</p>
			<Button>Перейти к оплате сейчас</Button>
			<img src={banner} alt='Оплата зарубежных счетов' />
		</section>
	);
};
const Logos = () => {
	return (
		<section className={styles.logos}>
			<img src={apple} alt='apple' />
			<img src={hetzer} alt='hetzer' />
			<img src={netflix} alt='netflix' />
			<img src={zoom} alt='zoom' />
			<img src={youtube} alt='youtube' />
			<img src={amazon} alt='amazon' />
			<img src={faceapp} alt='faceapp' />
			<img src={googleDrive} alt='googleDrive' />
			<img src={patreon} alt='patreon' />
			<img src={airbnb} alt='airbnb' />
			<img src={playStation} alt='playStation' />
			<img src={microsoft} alt='microsoft' />
			<img src={shopify} alt='shopify' />
		</section>
	);
};

const Cards = () => {
	return (
		<section className={styles.recommendations}>
			<h1>Наши рекомендации</h1>
			<div>
				<Card
					title={'Туристам'}
					text={'Наши продукты для российских и иностранных туристов '}
					img={card1}
					className={styles.card1}
				/>
				<Card
					title={'Оплата сервисов'}
					text={
						'Предоставляем возможность оплатить зарубежные сервисы и продукты'
					}
					img={card2}
					className={styles.card2}
				/>
			</div>
			<div>
				<Card
					title={'Аренда банковской карты'}
					text={'Аренда банковской карты на период путешествия'}
					img={card3}
					className={styles.card3}
				/>
				<Card
					title={'Для бизнеса'}
					text={
						'Оплата внешнеэкономических контрактов для Российских импортеров'
					}
					img={card4}
					className={styles.card4}
				/>
				<Card
					title={'Оформление банковских карт'}
					text={'Оформление зарубежных банковских карт'}
					img={card5}
					className={styles.card5}
				/>
			</div>
			<Button>Все продукты</Button>
		</section>
	);
};
const WhyUs = () => {
	return (
		<section className={styles.whyUs}>
			<h1>Почему Мы</h1>
			<div>
				<div>
					<h3>
						Тинькофф получил пять наград премии «Банк года» портала Банки.ру
					</h3>
					<p>
						Тинькофф — финансовая онлайн-экосистема, объединяющая полный спектр
						финансовых услуг для частных лиц и бизнеса. Особое внимание Тинькофф
						уделяет развитию лайфстайл-банкинга: экосистема дает клиентам
						возможность
					</p>
					<Button>Подробнее</Button>
				</div>
				<img src={dinero} alt='dinero' />
			</div>
		</section>
	);
};
const Service = ({ img, title, text }) => {
	return (
		<div>
			<img src={img} alt={title} />
			<h3>{title}</h3>
			<p>{text}</p>
		</div>
	);
};
const Services = () => {
	return (
		<section className={styles.services}>
			<h1>Услуги и продукты</h1>
			<div>
				{homeData.map(item => (
					<Service img={item.img} text={item.text} title={item.title} />
				))}
			</div>
		</section>
	);
};
const Clearing = () => {
	return (
		<section className={styles.clearing}>
			<h1>Клиринговые операции</h1>
			<p>
				Оплата международных счетов и контрактов, взаиморасчет сторон за товары,
				услуги, ценные бумаги и прочие активы
			</p>
			<Button>Подать заявку</Button>
			<img src={clearing} alt='Клиринговые операции' />
			<div>
				<div>
					<p>
						<span>Минимальная</span> комиссия за конвертацию и банковские
						<span>платежи</span>
					</p>
					<img
						src={clearing1}
						alt='Минимальная комиссия за конвертацию и банковские платежи'
					/>
				</div>
				<div>
					<p>
						Нестандартные <span>решения</span> поставленных <span>задач</span>
					</p>
					<img
						src={clearing2}
						alt='Нестандартные решения поставленных
						задач'
					/>
				</div>
			</div>
		</section>
	);
};

const HowItWorks = () => {
	const [isMobile, setMobile] = useState(false);
	useEffect(() => {
		const width = document.body.offsetWidth;
		if (width <= 1100) setMobile(true);
	}, []);
	return (
		<section className={styles.howItWorks}>
			<h1>Как это работает</h1>
			{isMobile ? (
				<div>
					<div className={styles.card}>
						{homeCardsData.map((item, index) => (
							<div key={v4()}>
								<div className={styles.index}>
									<p>{index + 1}</p>
								</div>
								<p className={styles.text}>{item.text}</p>
							</div>
						))}
					</div>
				</div>
			) : (
				<div>
					<div className={styles.card}>
						{homeCardsData.slice(0, 3).map((item, index) => (
							<div key={v4()}>
								<div className={styles.index}>
									<p>{index + 1}</p>
								</div>
								<p className={styles.text}>{item.text}</p>
							</div>
						))}
					</div>
					<div className={styles.card}>
						<img src={arrows} alt='' />
						<div>
							<div className={styles.index}>
								<p>4</p>
							</div>
							<p className={styles.text}>{homeCardsData[3].text}</p>
						</div>
					</div>
					<div className={styles.card} style={{ flexDirection: 'row-reverse' }}>
						{homeCardsData.slice(3, 6).map((item, index) => (
							<div key={v4()}>
								<div className={styles.index}>
									<p>{index + 5}</p>
								</div>
								<p className={styles.text}>{item.text}</p>
							</div>
						))}
					</div>
					<div className={styles.bottomContainer}>
						<div className={styles.card}>
							<div>
								<div className={styles.index}>
									<p>8</p>
								</div>
								<p className={styles.text}>{homeCardsData[7].text}</p>
							</div>
							<div>
								<div className={styles.index}>
									<p>9</p>
								</div>
								<p className={styles.text}>{homeCardsData[8].text}</p>
							</div>
						</div>
						<img src={chat} alt={''} />
					</div>
				</div>
			)}
		</section>
	);
};
const MobileApp = () => {
	return (
		<section className={styles.mobileApp}>
			<img src={phone} alt='Мобильное приложение для Вас' />
			<div>
				<div>
					<img src={qr} alt='Мобильное приложение' />
				</div>
				<h3>Мобильное приложение для Вас</h3>
				<p>По версии Global Finance — Best Digital Bank Award 2020</p>
				<div>
					<a href='/'>
						<img src={googlePlay} alt='googlePlay' />
					</a>
					<a href='/'>
						<img src={appStore} alt='appStore' />
					</a>
				</div>
			</div>
		</section>
	);
};

const Actual = () => {
	return (
		<section className={styles.actual}>
			<h1>Актуально сейчас</h1>

			<div className={styles.mainActual}>
				<div>
					<h3>Грот многоликий</h3>
					<p>
						Небольшое, на первый взгляд невзрачное, полуразрушевшееся и даже не
						очень древнее здание – Грот в Саду имени Баумана – АБ «Народный
						архитектор» отреставрировало со всей тщательностью, применимой к
						памятнику наследия. Сохранили романтическую...
					</p>
					<Button>Читать</Button>
				</div>

				<img src={actual} alt={'Актуально сейчас'} />
			</div>

			<div className={styles.actualCardContainer}>
				<div>
					<h3>Что нового в работе бизнеса в апреле 2023 года</h3>
					<p>
						Маркировка алкоголя в кегах, цифровой рубль и сезон летних кафе в
						Москве...
					</p>
					<a href='/'>Читать</a>
				</div>
				<div>
					<h3>MAD Architects: терминал-перо для аэропорта в Чанчуне</h3>
					<p>
						Компания MAD Architects под руководством Ма Янсуна выиграла
						международный конкурс на...
					</p>
					<a href='/'>Читать</a>
				</div>
				<div>
					<h3>MAD Architects: терминал-перо для аэропорта в Чанчуне</h3>
					<p>
						Компания MAD Architects под руководством Ма Янсуна выиграла
						международный конкурс на...
					</p>
					<a href='/'>Читать</a>
				</div>
			</div>
		</section>
	);
};

const PartnerShip = () => {
	return (
		<section className={styles.partnerShip}>
			<h1>Партнерская программа</h1>
			<div>
				<div>
					<h3>Без вложений зарабатывай вместе с нами</h3>
					<p>
						Гибкая схема видов вознаграждения Партнера
						<br />
						<br />
						Моментальные выплаты за каждого привлеченного клиента от Партнера
						<br />
						<br />
						Стать Партнером могут Юридические лица, ИП, Самозанятые
					</p>
					<Button>Подробнее</Button>
				</div>

				<img src={handsmash} alt='Партнерская программа' />
			</div>
		</section>
	);
};
const ForPartners = () => {
	return (
		<section className={styles.forPartners}>
			<h1>Партнерам</h1>
			<div>
				<Card
					title={'Банкам'}
					text={'Приглашаем к сотрудничеству кредитные организации'}
					img={card6}
					className={styles.card6}
				/>
				<Card
					title={'Для сервисов'}
					text={'Будем рады расширить линейку наших продуктов'}
					img={card7}
					className={styles.card7}
				/>
				<Card
					title={'Компаниям'}
					text={'Поможем в продвижении Ваших товаров и услуг'}
					img={card8}
					className={styles.card8}
				/>
				<Card
					title={'Вакансии'}
					text={'Стань частью нашей команды'}
					img={card9}
					className={styles.card9}
				/>
			</div>
		</section>
	);
};

const Chevron = () => <img src={chevron} alt='' />;
const Questions = () => {
	const classes = useStyles();
	return (
		<section className={styles.faq}>
			<h1>Вопросы и ответы</h1>
			<div>
				<div className={styles.accordionContainer}>
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>{' '}
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>{' '}
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>{' '}
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.root}>
						<AccordionSummary
							expandIcon={<Chevron />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography variant={'h3'} className={classes.question}>
								Какие гарантии, что приобретенный через приложение
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography className={classes.answer}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
								eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
				<img src={questions} alt='Вопросы и ответы' />
			</div>
		</section>
	);
};
const FindAnswer = () => {
	return (
		<section className={styles.findAnswer}>
			<h3>Получилось найти ответ?</h3>
			<div>
				<button>
					<img src={dislike} alt={'Нет'} /> Нет
				</button>
				<button>
					<img src={like} alt={'Да'} />
					Да
				</button>
			</div>
		</section>
	);
};
const SendFeedback = () => {
	return (
		<section className={classNames(styles.missing, styles.activeMissing)}>
			<div className={styles.innerPadding}>
				<h1>Оставьте свой отзыв</h1>
				<form>
					<textarea placeholder={'Введите свой ответ'}></textarea>
				</form>
				<Button>Ответить</Button>
			</div>
		</section>
	);
};
const Home = () => {
	return (
		<MainContainer>
			<Banner />
			<Logos />
			<Cards />
			<WhyUs />
			<Services />
			<Clearing />
			<HowItWorks />
			<MobileApp />
			<Actual />
			<PartnerShip />
			<ForPartners />
			<Questions />
			<FindAnswer />
			<FeedBack />
			<SendFeedback />
		</MainContainer>
	);
};

export default Home;
