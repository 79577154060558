import card1 from './card1.png';
import card2 from './card2.png';
import card3 from './card3.png';
import card4 from './card4.png';
import card5 from './card5.png';
export const homeData = [
	{
		img: card1,
		title: 'Оформление банковских карт туристам',
		text: 'Услуга по оформлению банковской карты иностранного государства для туристов желающих поехать зарубеж.'
	},
	{
		img: card2,
		title: 'Аренда банковской карты',
		text: 'Возможность получения в аренду банковскую карту на период путешествия заграницей России.'
	},
	{
		img: card3,
		title: 'Оплата небольших счетов зарубежом',
		text: 'Возможность подать заявку на оплату небольших счетов для оплаты зарубежных сервисов товаров и услуг'
	},
	{
		img: card4,
		title: 'Оформление карты российского банка',
		text: 'Услуга по оформлению банковской карты Российского банка для иностранного туриста с возможностью доставки по адресу отел или при встрече в аэропорту'
	},
	{
		img: card5,
		title: 'Оплаты  в России для иностранных граждан',
		text: 'Возможность оплаты иностранными гражданами банковской картой сервисов, товаров и счетов на территории России.'
	},
	{
		img: card2,
		title: 'Аренда карты Российского банка',
		text: 'Аренда Российской банковской карты для иностранных туристов'
	}
];

export const homeCardsData = [
	{ text: 'Выберите для себя продукт или услугу.' },
	{ text: 'Зарегистрируйтесь и создайте профайл в личном кабинете' },
	{ text: 'Заполните необходимые формы и документы' },
	{
		text: 'Создайте заявку с нужными Вами параметрами на получения продукта или услуги'
	},
	{
		text: 'Пополните свой баланс в личном кабинете для преавторизации необходимой суммы '
	},
	{
		text: 'Следите в личном кабинете о статуе оказания услуги или получения продукта'
	},
	{
		text: 'Наши сотрудники всегда будут с Вами на связи для максимально быстрого получения продукта'
	},

	{
		text: 'Ваш персональный менеджер сообщит Вам как можно получить заказанный продукт'
	},
	{
		text: 'Возвращайтесь к нам. Мы будем постоянно добавлять для Вас новые продукты и услуги'
	}
];
