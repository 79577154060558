import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '912px',
		margin: 'auto !important',
		borderBottom: '1px solid #DDDFE0',
		boxShadow: 'none',
		border: 'none',
		'&::before': {
			margin: 'auto !important',
			display: 'none'
		},
		'&:last-child': {
			margin: 'auto !important',
			borderBottom: 'none'
		},
		'&:hover': {
			margin: 'auto !important',
			boxShadow: 'none'
		},
		'&:Mui-expanded': {
			margin: 'auto !important'
		}
	},
	summary: {
		margin: 'auto !important',
		'& > div': {
			margin: 'auto !important'
		}
	},
	question: {
		padding: '6px 0',
		'& h3': {
			color: '#333333',
			fontSize: '1.375rem',
			fontWeight: 500
		}
	},
	answer: {
		maxWidth: '912px',
		fontSize: '0.95rem',
		padding: '13px 0',
		margin: 'auto !important'
	},
	chevron: {
		transition: 'transform 0.3s ease-in-out',
		'&.expanded': {
			transform: 'rotateZ(180deg)'
		}
	}
}));
