import styles from './Header.module.scss';
import DineroLogo from '../../ui/Logo/DineroLogo/DineroLogo';
import {
	otherNavigation,
	siteNavigation
} from '../../core/data/headerData/headerData';
import search from '../../assets/images/logos/search.png';
import playMarket from '../../assets/images/logos/playMarket.png';
import appStore from '../../assets/images/logos/appStore.png';
import logosSet from '../../assets/images/logos/logosSet.png';
import { v4 } from 'uuid';
import Burger from './Burger/Burger';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppSelector } from '../../core/hooks/storeHook';

const Header = () => {
	const [isVisible, setIsVisible] = useState(true);
	const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
	const { isMenuActive } = useAppSelector(state => state.MenuSlice);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			const isScrollingDown = currentScrollPos > prevScrollPos;

			if (currentScrollPos >= 200) {
				setIsVisible(!isScrollingDown);
				setPrevScrollPos(currentScrollPos);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos, isVisible]);

	return (
		<>
			<header className={styles.header}>
				<section className={styles.wrapper}>
					<div className={styles.siteNavigation}>
						<DineroLogo />
						<nav>
							<ul>
								{siteNavigation.map(item => (
									<li key={v4()}>
										<a href={item.link}>{item.label}</a>
									</li>
								))}
							</ul>
						</nav>
						<div className={styles.search}>
							<p>Поиск</p>
							<img src={search} alt='Поиск' />
						</div>
						<div className={styles.logos}>
							<a href='/'>
								<img src={appStore} alt='AppStore' />
							</a>
							<a href='/'>
								<img src={playMarket} alt='Playmarket' />
							</a>
						</div>
						<div className={styles.language}>
							<button>RU</button>
							<button>EN</button>
						</div>
						<button className={styles.login}>Вход</button>
					</div>
					<div className={styles.otherNavigation}>
						<nav>
							<ul>
								{otherNavigation.map(item => (
									<li key={v4()}>
										<a href={item.link}>{item.label}</a>
									</li>
								))}
							</ul>
						</nav>
						<div>
							<ul className={styles.currency}>
								<li>
									<span>USD</span> 82.03
								</li>
								<li>
									<span>EUR</span> 89.65
								</li>
								<li>
									<span>CNY</span> 82.03
								</li>
								<li>
									<span>KZT</span> 82.03
								</li>
								<li>
									<span>TRY</span> 82.03
								</li>
							</ul>
						</div>
						<img src={logosSet} alt='Логотипы' />
					</div>
				</section>
			</header>
			<header className={styles.mobile}>
				<DineroLogo />
				<div
					className={classNames(styles.mobileMenu, {
						[styles.active]: isMenuActive
					})}
				>
					<div className={styles.language}>
						<button>RU</button>
						<button>EN</button>
					</div>
					<button className={styles.login}>Вход</button>
					<nav>
						<ul>
							{siteNavigation.map(item => (
								<li key={v4()}>
									<a href={item.link}>{item.label}</a>
								</li>
							))}
						</ul>
					</nav>
					<div className={styles.search}>
						<p>Поиск</p>
						<img src={search} alt='Поиск' />
					</div>
					<div className={styles.logos}>
						<a href='/'>
							<img src={appStore} alt='AppStore' />
						</a>
						<a href='/'>
							<img src={playMarket} alt='Playmarket' />
						</a>
					</div>

					<nav>
						<ul>
							{otherNavigation.map(item => (
								<li key={v4()}>
									<a href={item.link}>{item.label}</a>
								</li>
							))}
						</ul>
					</nav>
					<div>
						<ul className={styles.currency}>
							<li>
								<span>USD</span> 82.03
							</li>
							<li>
								<span>EUR</span> 89.65
							</li>
							<li>
								<span>CNY</span> 82.03
							</li>
							<li>
								<span>KZT</span> 82.03
							</li>
							<li>
								<span>TRY</span> 82.03
							</li>
						</ul>
					</div>
					<img src={logosSet} alt='Логотипы' />
				</div>
				<Burger active={isMenuActive} onClick={() => console.log('')} />
			</header>
		</>
	);
};

export default Header;
