import React from 'react';
import styles from './Search.module.scss';
import search from '../../assets/images/Payment/search.png';
import classNames from 'classnames';
const Search = ({ className }) => {
	return (
		<div className={classNames(styles.search, className)}>
			<input type='text' placeholder={'Поиск'} />
			<img src={search} alt='Поиск' />
		</div>
	);
};

export default Search;
