import React from 'react';
import MainContainer from '../../containers/MainContainer/MainContainer';

import banner from '../../assets/images/404/Banner.png';
import styles from './404.module.scss';
import Missing from '../../ui/Missing/Missing';
const Error404 = () => {
	return (
		<MainContainer>
			<section className={styles.banner}>
				<h1>404 :(</h1>
				<div className={styles.bannerContainer}>
					<div>
						<h3>Мы верим, что Вы найдете, то что ищите!</h3>
						<p>
							В данный момент такой страницы не существует или она находится в
							разработке
						</p>
					</div>

					<img src={banner} alt={'404'} />
				</div>
			</section>
			<Missing />
		</MainContainer>
	);
};

export default Error404;
