import styles from './MobileMenu.module.scss';

import { setMenuMobileActive } from '../../core/store/slices/MenuSlice';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../core/hooks/storeHook';
import {
	otherNavigation,
	siteNavigation
} from '../../core/data/headerData/headerData';
import { v4 } from 'uuid';
import search from '../../assets/images/logos/search.png';
import appStore from '../../assets/images/logos/appStore.png';
import playMarket from '../../assets/images/logos/playMarket.png';
import logosSet from '../../assets/images/logos/logosSet.png';

const MobileMenu = () => {
	const { isMobileMenuActive } = useAppSelector(state => state.MenuSlice);
	const dispatch = useAppDispatch();

	const toggleMobileMenu = () => {
		document.body.classList.remove('lock');
		dispatch(setMenuMobileActive(false));
	};

	return (
		<div
			onClick={toggleMobileMenu}
			className={
				isMobileMenuActive
					? classNames(styles.mobileMenuWrapper, styles.visible)
					: styles.mobileMenuWrapper
			}
		>
			<div
				onClick={event => event.stopPropagation()}
				className={
					isMobileMenuActive
						? classNames(styles.mobileMenu, styles.visible)
						: styles.mobileMenu
				}
			>
				<nav>
					<ul>
						{siteNavigation.map(item => (
							<li key={v4()}>
								<a href={item.link}>{item.label}</a>
							</li>
						))}
					</ul>
				</nav>
				<div className={styles.search}>
					<p>Поиск</p>
					<img src={search} alt='Поиск' />
				</div>
				<div className={styles.logos}>
					<a href='/'>
						<img src={appStore} alt='AppStore' />
					</a>
					<a href='/'>
						<img src={playMarket} alt='Playmarket' />
					</a>
				</div>
				<div className={styles.language}>
					<button>RU</button>
					<button>EN</button>
				</div>
				<button className={styles.login}>Вход</button>
			</div>
			<div className={styles.otherNavigation}>
				<nav>
					<ul>
						{otherNavigation.map(item => (
							<li key={v4()}>
								<a href={item.link}>{item.label}</a>
							</li>
						))}
					</ul>
				</nav>
				<div>
					<ul className={styles.currency}>
						<li>
							<span>USD</span> 82.03
						</li>
						<li>
							<span>EUR</span> 89.65
						</li>
						<li>
							<span>CNY</span> 82.03
						</li>
						<li>
							<span>KZT</span> 82.03
						</li>
						<li>
							<span>TRY</span> 82.03
						</li>
					</ul>
				</div>
				<img src={logosSet} alt='Логотипы' />
			</div>
		</div>
	);
};

export default MobileMenu;
