import styles from './ServiceForm.module.scss';
import settigs from '../../assets/images/Payment/settings.svg';
import check from '../../assets/images/Payment/check.svg';
import questions from '../../assets/images/Payment/questions.png';
import Button from '../Button/Button';
import React from 'react';

const ServiceForm = () => {
	return (
		<div className={styles.notFound}>
			<div className={styles.border}></div>
			<div className={styles.notFoundBanner}>
				<div className={styles.settings}>
					<img src={settigs} alt='' />
					<h1>
						Не нашли нужный сервис?
						<br />
						Напишите нам
					</h1>
				</div>
				<div className={styles.try}>
					<p>
						Попробуем связаться <br />
						<span>с этим сервисом</span>
					</p>
					<div>
						<img src={check} alt='' />
					</div>
				</div>
			</div>
			<div className={styles.formContainer}>
				<form>
					<input type='text' placeholder={'Название сервиса*'} />
					<input type='text' placeholder={'Фамилия, имя, отчество*'} />
					<input type='text' placeholder={'Контактный телефон или  email*'} />
					<div>
						<p>Когда данный сервис будет доступен к оплате мы уведомим Вас</p>
					</div>
				</form>
				<div>
					<img src={questions} alt='Вопросы' />
					<Button>Запросить</Button>
				</div>
			</div>
		</div>
	);
};

export default ServiceForm;
